import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Container, MetricCard } from '@gitlab-rtsensing/component-library';
import logo from '../assets/sensing-logo.png';
import { lazyImport } from 'utils/lazy-import';
import ImageTile from 'components/image-tile';
import RequestAccessTile from 'components/request-access-tile';
import { AuthPermissions, RootAuthPermissions } from 'types';
import './home.scss';
import RequestAccessModal from 'components/request-access-modal';
import { useLocation } from 'react-router-dom';
import { hyphenToCamel, camelToHyphen } from 'utils/string-utils';

const SupplyTile = lazy(() => lazyImport('@amgen/rtsensing-supply-tile'));
const FinanceTile = lazy(() => lazyImport('@amgen/rtsensing-finance-tile'));
const BrandTile = lazy(() => lazyImport('@amgen/rtsensing-brand-tile'));
const PeopleTile = lazy(() => lazyImport('@amgen/rtsensing-people-tile'));
const PipelineTile = lazy(() => lazyImport('@amgen/rtsensing-pipeline-tile'));
const PrioritizedAgendaTile = lazy(() =>
  lazyImport('@amgen/rtsensing-prioritized-agenda-tile'),
);
//const EarningsTile = lazy(() => lazyImport('@amgen/rtsensing-external-tile'));

type TileConfig = {
  key: string;
  tile: JSX.Element;
  label: string;
  isAuth?: boolean;
};

type SlotConfig = {
  slotAuth?: boolean;
  tiles: Array<TileConfig>;
};

interface HomePageProps {
  tilePermissions: AuthPermissions;
  sensingAuthorization: RootAuthPermissions;
}

const DefaultLayout: Array<SlotConfig> = [
  {
    tiles: [
      {
        key: 'people',
        label: 'People',
        tile: <PeopleTile />,
      },
    ],
  },
  {
    tiles: [
      {
        key: 'finance',
        label: 'Finance',
        tile: <FinanceTile />,
      },
    ],
  },
  {
    tiles: [
      {
        key: 'supply',
        label: 'Supply',
        tile: <SupplyTile />,
      },
    ],
  },
  {
    tiles: [
      {
        key: 'pipeline',
        label: 'Pipeline',
        tile: <PipelineTile />,
      },
    ],
  },
  /*   {
      tiles: [
        {
          key: 'earnings',
          label: 'Earnings',
          tile: <EarningsTile />,
        },
      ],
    }, */
  {
    tiles: [
      {
        key: 'brand',
        label: 'Brand',
        tile: <BrandTile />,
      },
    ],
  },
  {
    tiles: [
      {
        key: 'prioritizedAgenda',
        label: 'Prioritized Agenda',
        tile: (
          // TODO: Remove Suspense after PA tile has been developed.
          <Suspense
            fallback={
              <ImageTile
                title="Prioritized Agenda"
                keyParam="prioritized_agenda_img_url"
                params="prioritizedAgenda"
                linkURL={
                  process.env.REACT_APP_PRIORITIZED_AGENDA_REDIRECT_LINK || ''
                }
              />
            }
          >
            <PrioritizedAgendaTile />
          </Suspense>
        ),
      },
      /* {
        key: 'horizonEvents',
        label: 'Horizon Integration Planning',
        tile: (
          <ImageTile
            title="Horizon Integration Planning"
            keyParam="horizon_events_img_url"
            params="horizonEvents"
            linkURL={process.env.REACT_APP_HORIZON_EVENTS_REDIRECT_LINK || ''}
          />
        ),
      }, */
    ],
  }
];

export default function HomePage(props: HomePageProps): JSX.Element {
  const { tilePermissions } = props;
  const [orderedTiles, setOrderedTiles] = useState([] as Array<SlotConfig>);
  const [requestAccessOpened, setRequestAccessOpened] = useState(false);

  const peopleRef = useRef<HTMLDivElement>(null);
  const financeRef = useRef<HTMLDivElement>(null);
  const supplyRef = useRef<HTMLDivElement>(null);
  const pipelineRef = useRef<HTMLDivElement>(null);
  const brandRef = useRef<HTMLDivElement>(null);
  const prioritizedAgendaRef = useRef<HTMLDivElement>(null);
  const earningsRef = useRef<HTMLDivElement>(null);
  const experimentalQnARef = useRef<HTMLDivElement>(null);
  let homecount = 0;
  const homeCountVar = process.env.REACT_APP_HOME_COUNT as string;

  if (sessionStorage.getItem(homeCountVar) === null) {
    sessionStorage.setItem(homeCountVar, JSON.stringify(0)); //should be per env varible
  } else {
    homecount = Number(sessionStorage.getItem(homeCountVar));
  }

  const refs: { [key: string]: React.RefObject<HTMLDivElement> } = {
    people: peopleRef,
    finance: financeRef,
    supply: supplyRef,
    pipeline: pipelineRef,
    brand: brandRef,
    prioritizedAgenda: prioritizedAgendaRef,
    earnings: earningsRef,
    experimentalQnARef: experimentalQnARef,
  };

  const getRef = (key: string): React.RefObject<HTMLDivElement> | null => {
    return refs[key] || null;
  };

  const location = useLocation();

  const isInViewport = (element: HTMLDivElement) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const scrollToSection = (): void => {
    if (location) {
      const currentRef: React.RefObject<HTMLDivElement> | null = getRef(
        hyphenToCamel(location.hash.slice(1)),
      );

      if (currentRef && currentRef.current) {
        const isElementInView = isInViewport(currentRef.current);

        if (!isElementInView) {
          window.scroll({
            top: currentRef.current.getBoundingClientRect().top - 60,
            behavior: 'auto',
          });
        }
      }
    }
  };

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        scrollToSection();
      }, 2500);
    }
  }, [location.hash]);

  const constructSlots = useCallback(() => {
    if (tilePermissions) {
      const ordered: Array<SlotConfig> = [];

      DefaultLayout.forEach((slotConfig: SlotConfig) => {
        let slotAuth = false;
        const orderedSlot = { ...slotConfig };
        orderedSlot.tiles = [...orderedSlot.tiles];

        // Iterate through the tiles and perform the following evaluation
        // -- Check if SLOT is authorized | True if ANY tile is authorized
        // -- Check if TILE is authorized | True if permission for tile is true
        orderedSlot.tiles.forEach((tileConfig: TileConfig, index: number) => {

          // Earnings key is used for external-workstream
          const tileConfigKey = tilePermissions[tileConfig.key === 'earnings' ? 'external' : tileConfig.key];
          const authTileConfig = { ...tileConfig };
          slotAuth = tileConfigKey ? true : slotAuth;
          authTileConfig.isAuth = tileConfigKey;
          orderedSlot.tiles[index] = authTileConfig;
        });
        orderedSlot.slotAuth = slotAuth;

        // Apply order to slot
        // -- Authorized Slots are pushed to the front
        // -- Unauthorized Slots are pushed to the back

        if (
          orderedSlot.tiles.length > 1 &&
          !(orderedSlot.tiles[0].isAuth === orderedSlot.tiles[1].isAuth)
        ) {
          orderedSlot.tiles.forEach(tile => {
            const singleTile = {
              slotAuth: tile.isAuth,
              tiles: [
                tile,
                {
                  key: '',
                  label: '',
                  tile: <div></div>,
                  isAuth: true,
                },
              ],
            };
            tile.isAuth
              ? ordered.unshift(singleTile)
              : ordered.push(singleTile);
          });
        } else if (slotAuth) {
          ordered.unshift(orderedSlot);
        } else {
          ordered.push(orderedSlot);
        }
      });

      setOrderedTiles(ordered);
    }
  }, [tilePermissions]);

  useEffect(() => {
    if (tilePermissions) {
      constructSlots();
    }
  }, [constructSlots, tilePermissions]);

  const renderSlots = () => {
    const slots: Array<JSX.Element> = [];
    const sensingAuthorizationArr = props.sensingAuthorization;
    let sensingAuthFirstAccessPage = '';
    for (const key in sensingAuthorizationArr) {
      if (sensingAuthorizationArr[key]) {
        //external workstream is intelligence
        sensingAuthFirstAccessPage = key === 'external' ? 'intelligence' : key;
        break
      }
    }

    const homeAuthorizationArr = orderedTiles.filter((key) => {
      return key.slotAuth === true;
    })

    if (orderedTiles) {
      let availableIndx = 0;
      const accessCount = orderedTiles.filter((slot: SlotConfig) => slot.slotAuth === true).length;
      const redirectionContition = homecount === 1 && accessCount > 0 ? true : false

      orderedTiles.forEach((slot: SlotConfig, index: number) => {
        slots.push(
          <>
            {slot.tiles.map((tile: TileConfig) => {
              if (tile.isAuth) {
                availableIndx++;
                return (
                  <div
                    key={availableIndx}
                    className={`home-page-grid-item home-page-grid-item--slot${availableIndx}`}
                  >
                    <div
                      key={tile.key}
                      ref={getRef(tile.key)}
                      id={camelToHyphen(tile.key)}
                    >
                      <Suspense
                        fallback={
                          <MetricCard>
                            <MetricCard.Content>
                              <div />
                            </MetricCard.Content>
                          </MetricCard>
                        }
                      >
                        {tile.tile}
                      </Suspense>
                    </div>
                  </div>
                );
              } else if (accessCount === 0 && homeAuthorizationArr.length === 0) {
                //Check on main sensing authorization for special access like people, pipeline where tile access & page access can be different 
                if (sensingAuthFirstAccessPage !== '') {
                  sessionStorage.setItem(homeCountVar, JSON.stringify(1));
                  window.location.href = '/' + sensingAuthFirstAccessPage;
                } else {
                  window.location.href = '/about';
                }
                return <></>
              } else if (accessCount === 1 && homeAuthorizationArr.length > 0) {
                let key = homeAuthorizationArr[0].tiles[0].key;
                //Due to PA key name difference & url as 'prioritized-agenda' instead of 'prioritizedAgenda'
                //Also, external workstream is intelligence
                if (key === 'prioritizedAgenda') {
                  key = 'prioritized-agenda';
                } else if (key === 'external') {
                  key = 'intelligence';
                }

                if (key === 'experimentalQna' && sensingAuthorizationArr?.external) {
                  //window.location.href = '/intelligence'; //experimental Qna belongs to external workstream, if it has permission
                } else if (key !== 'experimentalQna') {
                  sessionStorage.setItem(homeCountVar, JSON.stringify(1));
                  if (!redirectionContition) {
                    window.location.href = '/' + key;
                  }
                } else {
                  sessionStorage.setItem(homeCountVar, JSON.stringify(1));
                  if (!redirectionContition) {
                    window.location.href = '/' + sensingAuthFirstAccessPage;
                  }
                }
                return <></>
              } else if (accessCount <= 3 && accessCount > 1 || (tile.key === 'experimentalQna' && tile.isAuth === false)) {
                //There is special case of experimentalQna where it should be shown only if it is authorized, no Request Access Block for this one
                return <></>
              } else if (accessCount > 3) {

                return (
                  <div key={index} className={`home-page-grid-item home-page-grid-item--slot${0}`}>
                    <RequestAccessTile
                      key={tile.key}
                      title={tile.label}
                      openRequestAccessHandler={(bool: boolean) =>
                        setRequestAccessOpened(bool)
                      }
                    />
                  </div>
                );
              }
            })}
          </>
        );
      });
    }

    return slots;
  };

  return (
    <>
      <Container className="home-page-container">
        <div className="home-page-logo-container">
          <img
            className="home-page-logo"
            src={`${process.env.REACT_APP_HOST}/${logo}`}
            alt="Logo"
          />
          <p className="ops-font-inter home-page-logo-text home-page-logo-text--large">
            Welcome to the Future of Decision Making
          </p>
          <p className="ops-font-inter home-page-logo-text home-page-logo-text--small">
            THIS IS AMGEN SENSING
          </p>
        </div>
        <div className="home-page-grid">
          <>{renderSlots()}</>
        </div>
      </Container>
      <RequestAccessModal
        opened={requestAccessOpened}
        onRequestAccessModalClosed={() => setRequestAccessOpened(false)}
      />
    </>
  );
}
