import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { RootAuthPermissions } from 'types';
import App from './App';
import HomePage from 'pages/home';

interface HomeAppProps {
  permissions: RootAuthPermissions;
}

export const RootApp = (props: HomeAppProps) => {
  //Below are invalid permission for redirection
  const exclusions = ['homeAuthorization', 'horizon', 'curation', 'notificationsSubscriptions', 'notificationsCurator', 'notificationsConfigure', 'about', 'home', 'notificationsReporting', 'admin', 'notifications'];
  const sensingAuthorization = Object.assign({}, props.permissions as RootAuthPermissions);
  exclusions.forEach((exclusion: string) => {
    delete sensingAuthorization[exclusion];
  });

  return (
    <App
      componentToRender={
        <HomePage tilePermissions={props.permissions.homeAuthorization} sensingAuthorization={sensingAuthorization} />
      }
      route="/home"
      themeService={null}
      permissions={props.permissions.homeAuthorization}
    />
  );
};

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: RootApp,
  errorBoundary() {
    return <div>This renders when a catastrophic error occurs</div>;
  },
});

export const bootstrap = reactLifecycles.bootstrap;
export const mount = reactLifecycles.mount;
export const unmount = reactLifecycles.unmount;
